.App {
  text-align: center;
}

.page h1 {
  font-size: 5rem;
  color: black;
}

.page h2 {
  font-size: 1.6rem;
  color: black;
}

.page a {
  color: #0063ce
}

/* the page layout component
   applies to all content
   but not header & footer */
.page p {
  max-width: 650px;
  float: none;
  margin: auto;
  padding: 1rem;
}